import { 
  _
} from "$Imports/Imports";

import {
  SharedSecurityContext
} from "$Shared/utilities/Security/ApplicationSecuritySettings"

// Global Settings and User Manager

class ApplicationSecuritySettings {
  public canAccessReportingPortal(): boolean {
    const reportingRoles = ["administrator", "manager", "salesrep", "reporter"];
    return SharedSecurityContext.hasRole(reportingRoles);
  }
}

// Create a singleton for securiry settings.
export const SecurityContext = new ApplicationSecuritySettings();