import {
  React,
  _,
  User
} from "$Shared/imports/Imports";

import {
  SvgIconProps
} from "$Shared/imports/MaterialUIStyles";

import {
  BookedSalesViewPage
} from "$Pages/BookedSales";

import {
  QuoteConversionViewPage
} from "$Pages/QuoteConversion";

import {
  QuoteReviewsViewPage
} from "$Pages/QuoteReviews";

import {
  DeliveredFreightViewPage
} from "$Pages/DeliveredFreight"

import {
  LaneRevenueViewPage
} from "$Pages/LaneRevenue";

import {
  SalesOpportunitiesViewPage
} from "$Pages/SalesOpportunities";

import {
  SalesHistoryViewPage
} from "$Pages/SalesHistory";

import {
  CapacityReportPage
} from "$Pages/CapacityReport";

import {
  CapacityReportConfigViewPage
} from "$Pages/CapacityReportConfig";

import {
  SharedSecurityContext
} from "$Shared/utilities/Security/ApplicationSecuritySettings"

interface IMatchProps<P> {
  match?: {
    params?: P;
  };
}

export interface INavigationItem {
  url: string;
  redirectUrls?: string[];
  label: string;
  externalLink: boolean;
  enabled: boolean | (<T = User>(navItem: INavigationItem, securityContext: T) => boolean);
  hasAccess?: boolean | ((navItem: INavigationItem, securityContext: User | null) => boolean);
  childNavigation?: INavigationItem[];
  icon?: React.ComponentType<SvgIconProps>;
  component?: React.ComponentClass | React.FunctionComponent;
  title?: string;
  isRoot: boolean;
  rootUrl: string;
  rootUrlLabel?: string;
  rolesToAccess?: string[];
  companiesToAccess?: string[];
  displayMainLayout?: boolean;
}

export interface ISideNavigationItem extends INavigationItem {
  rootUrlPageUrl: string;
}

function hasAccessByContext(navItem: INavigationItem, securityContext: User | null): boolean {
  var canAccess = false;
  const roles = SharedSecurityContext.getRoles(securityContext);
  
  if (roles && navItem.rolesToAccess) {
    var overlap = _.intersection(navItem.rolesToAccess, roles);
    canAccess = overlap.length > 0;
  }

  return canAccess;
}

export const mainNavigation: INavigationItem[] = [
  {
    redirectUrls: ["/"],
    url: "/reports",
    label: "Booked Sales",
    externalLink: false,
    component: BookedSalesViewPage,
    hasAccess: hasAccessByContext,
    rolesToAccess: ["report-booked-sales:view", "salesrep", "administrator", "manager", "reporter"],
    enabled: true,
    isRoot: true,
    rootUrl: "/reports",
    rootUrlLabel: "Library",
    displayMainLayout: true,
  },
  {
    url: "/quote_conversion",
    label: "Quote Conversion",
    externalLink: false,
    component: QuoteConversionViewPage,
    hasAccess: hasAccessByContext,
    rolesToAccess: ["administrator", "manager", "salesrep", "reporter"],
    enabled: true,
    isRoot: false,
    rootUrl: "/reports",
    displayMainLayout: true,
  },
  {
    url: "/quote_reviews",
    label: "Quote Reviews",
    externalLink: false,
    component: QuoteReviewsViewPage,
    hasAccess: hasAccessByContext,
    rolesToAccess: ["administrator", "manager", "reporter"],
    enabled: true,
    isRoot: false,
    rootUrl: "/reports",
    displayMainLayout: true,
  },
  {
    url: "/delivered_freight",
    label: "Delivered Freight",
    externalLink: false,
    component: DeliveredFreightViewPage,
    hasAccess: hasAccessByContext,
    rolesToAccess: ["administrator", "manager", "salesrep", "reporter"],
    enabled: true,
    isRoot: false,
    rootUrl: "/reports",
    displayMainLayout: true,
  },
  {
    url: "/lane_revenue",
    label: "Lane Revenue",
    externalLink: false,
    component: LaneRevenueViewPage,
    hasAccess: hasAccessByContext,
    rolesToAccess: ["report-lane-revenue:view", "administrator", "manager", "reporter"],
    enabled: true,
    isRoot: false,
    rootUrl: "/reports",
    displayMainLayout: true,
  },
  {
    url: "/sales_opportunities",
    label: "Sales Opportunities",
    externalLink: false,
    component: SalesOpportunitiesViewPage,
    hasAccess: hasAccessByContext,
    rolesToAccess: ["report-sales-opportunities:view", "administrator", "manager", "reporter"],
    enabled: true,
    isRoot: false,
    rootUrl: "/reports",
    displayMainLayout: true,
  },
  {
    url: "/sales_history",
    label: "Customer Sales History",
    externalLink: false,
    component: SalesHistoryViewPage,
    hasAccess: hasAccessByContext,
    rolesToAccess: ["report-sales-history:view", "salesrep", "administrator", "manager", "reporter"],
    enabled: true,
    isRoot: false,
    rootUrl: "/reports",
    displayMainLayout: true,
  },
  {
    url: "/capacity_report",
    label: "Capacity Report",
    externalLink: false,
    component: CapacityReportPage,
    hasAccess: hasAccessByContext,
    enabled: true,
    isRoot: false,
    rootUrl: "/reports",
    displayMainLayout: true,
    rolesToAccess:[ "administrator", "manager", "reporter" ]
  },
  {
    url: "/admin",
    label: "Capacity Report",
    externalLink: false,
    component: CapacityReportConfigViewPage,
    hasAccess: hasAccessByContext,
    rolesToAccess: ["administrator"],
    enabled: true,
    isRoot: true,
    rootUrl: "/admin",
    rootUrlLabel: "Admin",
    displayMainLayout: true
  }
];